.dialogBg {
    width: 576px;
    max-width: 100%;
    line-height: 20px;

    .dialogHeader {
        padding: 24px 32px 20px 46px;
    }

    .searchBox,
    .tokenItem,
    .dialogHeader,
    .dialogInfo,
    .staking,
    .unstaking,
    .summary2,
    .payInfo {
        border-bottom: 1px solid #20253E;
    }

    .searchBox {
        padding: 36px 40px 30px;

        .TokenSelector-token-row {
            padding-bottom: 0;
            height: 72px;
        }
    }

    .settingInfo {
        padding: 18px 40px 24px 40px;

    }

    .dialogInfo {
        padding: 26px 40px 30px 42px;

        .tab2 {
            margin-bottom: 18px;

            .tabItem {
                border: 1px solid #9B96FF;
            }

            .tabItemActive {
                background: #9B96FF;
                color: #FFFFFF;
            }
        }

        .tab {
            margin-bottom: 26px;

            .tabItem {
                border: 1px solid #F89542;
            }

            .tabItemActive {
                background: #F89542;
                color: #FFFFFF;
            }
        }

        .tab,
        .tab2 {
            .tabItem {
                width: 110px;
                height: 36px;
                border-radius: 5px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .Exchange-swap-section {
            padding: 8px 28px 14px 12px;
            border-radius: 10px;
            background: #20253E;
        }

        .Exchange-swap-section-top {
            color: rgb(238, 242, 249, 0.5);
            font-size: 12px;
        }

        .Exchange-swap-max {
            position: static;
        }
    }

    .stakedAmount {
        width: 100%;
        height: 72px;
        padding-left: 14px;
        padding-right: 28px;
        background: #20253E;
        border-radius: 10px;

        .max {
            width: 68px;
            height: 36px;
            border: 1px solid #F89542;
            border-radius: 9px;
            cursor: pointer;
        }
    }

    .burn {
        padding: 18px 40px 0 46px;
    }

    .unstaking {
        padding: 0 40px 24px 46px;
    }

    .staking {
        padding: 18px 40px 44px 46px;

        .time {
            position: relative;
            width: 228px;
            height: 72px;
            background: #20253E;
            border-radius: 10px;

            .week-input {
                z-index: 9;
            }

            .week-input2 {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                cursor: text;
            }
        }

        .timeTab {
            grid-template-columns: repeat(3, 132px);
            grid-gap: 10px;

            .tabItem {
                height: 36px;
                background: #20253E;
                color: #6B75A5;
                cursor: pointer;
            }

            .tabItemActive {
                color: #FFFFFF;
            }
        }
    }

    .summary {
        padding: 24px 40px 0 46px;
    }

    .summary2 {
        padding: 36px 40px 30px 46px;
    }

    .summary3 {
        padding: 12px 40px 0 46px;
    }

    .claimInfo {
        padding: 28px 40px 0 46px;
    }

    .sliderBox {
        margin-top: 72px;

        .MuiSlider-root.Mui-disabled {
            color: #9B96FF !important;
        }

        .MuiSlider-rail,
        .MuiSlider-track {
            height: 8px;
            border-radius: 4px;
        }

        .MuiSlider-thumb.Mui-disabled {
            visibility: hidden;
        }
    }

    .receiveBg {
        height: 72px;
        padding: 0 30px;
        background: #101224;
        border-radius: 10px;
    }

    .payInfo {
        padding: 36px 40px 30px 46px;
    }

    .warnBox {
        padding: 36px 40px 0 46px;

        .warnInfo {
            padding: 20px;
            background: rgba(248, 149, 66, 0.2);
            border: 1px solid #FCA149;
            border-radius: 10px;
        }
    }

    .summaryInfo,
    .summaryInfo2,
    .summaryInfo3 {
        background: #20253E;
        border: 1px solid #20253E;
        border-radius: 10px;
    }

    .summaryInfo {
        padding: 20px 40px 24px;
    }

    .summaryInfo2 {
        padding: 20px 40px;
    }

    .summaryInfo3 {
        .box1 {
            padding: 20px 40px 16px;
        }

        .box2 {
            padding: 16px 40px;
        }

        .box3 {
            padding: 16px 40px 20px;
        }

        .box1,
        .box2 {
            border-bottom: 1px solid #3E4466;
        }
    }

    .rewards {
        padding: 18px 40px 36px 46px;

        .rewardInfo {
            .reward {
                flex: 1;
                min-height: 72px;
                background: #20253E;
                border: 1px solid #20253E;
                border-radius: 10px;
            }

            .add {
                margin: 0 16px;
            }
        }
    }

    .MuiButton-root {
        border-radius: 9px;
        max-width: 100%;
        max-height: 40px;
        line-height: 40px;
        font-size: 13px;
        font-weight: 700;
        font-family: "DM Sans";
    }

    .operation2 {
        margin-bottom: 24px;
        padding: 0 48px;

        .stakeBtnBox,
        .cancelBtnBox {
            height: 40px;

            .MuiButton-root {
                width: 228px;
            }
        }

        .cancelBtnBox {
            .MuiButton-root {
                background-color: #20253E;
                color: #6B75A5;
            }
        }

        .stakeBtnBox {
            .MuiButton-root {
                background-color: transparent;
                border: 1px solid #F89542;
                color: #F89542;
            }

            .MuiButton-root:hover {
                background-color: #F89542;
                border: 1px solid #F89542;
                color: #181A51;
            }
        }
    }

    .operation3 {
        padding: 30px 40px 44px 46px;

        .unstakeBtnBox,
        .claimBtnBox {
            width: 100%;
            height: 40px;

            .MuiButton-root {
                width: 100%;
            }
        }

        .unstakeBtnBox {
            .MuiButton-root {
                background-color: #101224;
                color: #2E2B49;
            }
        }

        .claimBtnBox {
            .MuiButton-root {
                background-color: #FCA149;
                color: #FFFFFF;
            }

            .MuiButton-contained.Mui-disabled {
                background-color: #666666;
            }
        }
    }

    .rewardBox {
        padding: 18px;
        background: #20253E;
        border: 1px solid #20253E;
        border-radius: 10px;

        .rewardTitle {
            line-height: 28px;
        }

        .rewardVal {
            line-height: 48px;
        }
    }

    .operation4 {
        padding: 26px 40px 26px 46px;

        .editBtnBox {
            width: 100%;
            height: 40px;

            .MuiButton-root {
                width: 100%;
            }
        }

        .editBtnBox {
            .MuiButton-root {
                background-color: #9B96FF;
                color: #FFFFFF;
            }
        }
    }

    .operation5 {
        padding-top: 30px;
        padding-bottom: 44px;

        .claimBtnBox {
            width: 100%;
            height: 40px;

            .MuiButton-root {
                width: 100%;
            }
        }

        .claimBtnBox {
            .MuiButton-root {
                background-color: #FCA149;
                color: #FFFFFF;
            }

            .MuiButton-contained.Mui-disabled {
                background-color: #666666;
            }
        }
    }

    .editBtnContainer,
    .closeBtnContainer,
    .confirmationContainer {
        padding: 26px 40px 26px 46px;
    }

    .editBtnContainer button.App-cta {
        background: #9B96FF;
        border: 1px solid #9B96FF;
    }

    .networkList {
        margin: 20px 24px;

        .networkItem {
            padding: 8px 12px;
            min-height: 48px;
            cursor: pointer;

            &:not(:first-child) {
                margin-top: 8px;
            }

            .isConnected {
                .connected {
                    margin-left: 8px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #5AD769;
                }
            }
        }

        .networkItemActive {
            background: #20253E;
            border-radius: 6px;
        }
    }
}

.dialogBg-wallet {
    width: 500px;
}

.dialogBg-network {
    width: 400px;
}

.MuiDialog-paper {
    background-color: #16182D;
    margin: 0 !important;
    border-radius: 10px;

    &::-webkit-scrollbar {
        width: 0;
    }
}

@media only screen and (max-width: 1080px) {
    .dialogBg {
        width: 92vw;

        .dialogHeader {
            padding: 24px 16px 20px 18px;
        }

        .searchBox {
            padding: 36px 20px 30px;
        }

        .dialogInfo {
            padding: 26px 16px 30px 16px;
        }

        .payInfo {
            padding: 26px 16px 20px 16px;
        }

        .warnBox {
            padding: 26px 16px 0 16px;
        }

        .staking {
            padding: 18px 16px 44px 16px;
        }

        .burn {
            padding: 18px 16px 0 16px;
        }

        .unstaking {
            padding: 0 16px 24px 16px;
        }

        .summary2 {
            padding: 26px 16px 30px 16px;
        }

        .summary3 {
            padding: 12px 16px 0 16px;
        }

        .claimInfo {
            padding: 18px 16px 0 16px;
        }

        .summary {
            padding: 24px 16px 0 16px;
        }

        .summaryInfo {
            padding: 20px 16px 24px;
        }

        .summaryInfo2 {
            padding: 20px 16px;
        }

        .rewards {
            padding: 18px 16px 36px 16px;
        }

        .operation2 {
            padding: 0 18px;
        }

        .editBtnContainer,
        .closeBtnContainer,
        .confirmationContainer {
            padding: 14px 16px;
        }
    }
}

@media only screen and (max-width: 680px) {
    .dialogBg {
        .staking {
            .timeTab {
                grid-template-columns: repeat(2, 132px);
                grid-gap: 8px;
            }
        }

        .operation2 {

            .cancelBtnBox,
            .stakeBtnBox {
                width: 100%;

                .MuiButton-root {
                    flex: 1;
                    width: 100%;
                }
            }
        }
    }

    .dialogBg-wallet {
        width: 300px;

        .connectBtnBox,
        .getBtnBox {
            width: 100%;
        }

        .getBtnBox {
            margin-top: 12px;
        }
    }

    .dialogBg-network {
        width: 90vw;
    }

    .networkList {
        margin: 20px 16px;
    }
}