* {
  outline: none;
  // -ms-overflow-style: none !important;
  // /* IE and Edge */
  // scrollbar-width: none !important;

  // /* Firefox */
  // &::-webkit-scrollbar {
  //   display: none !important;
  // }
}

// div,
// span,
// p {
//   caret-color: transparent;
// }

html,
body,
#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 14px;
  box-sizing: border-box;
}

// body::-webkit-scrollbar {
//   width: 4px;
//   background: #0a0a0a
// }

// body::-webkit-scrollbar-track {
//   background: 0 0
// }

// body::-webkit-scrollbar-thumb {
//   background: #E4700E;
//   border-radius: 16px;
// }

// body::-webkit-scrollbar-thumb:hover {
//   background: #E4700E;
// }

#root {
  position: relative;
}

.app {
  color: #ffffff;
  font-family: "Poppins";
}

.font-family-DMSans {
  font-family: "DM Sans";
}

.font-family-Poppins {
  font-family: "Poppins";
}

.MuiButtonBase-root {
  display: flex;
  align-items: center;
}

.container {
  width: 1384px;
  max-width: 100%;
}

.app-container {
  height: 100vh;
}

.esbt-container {
  width: 100vw;
  height: 100vh;
  background: url(./assets/images/bg_esbt.svg);
  background-color: #5A0FA6;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: scroll;
}

.flex-1 {
  flex: 1;
}

.mr-8 {
  margin-right: 8px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-14 {
  margin-left: 14px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-32 {
  margin-left: 32px;
}

.ml-36 {
  margin-left: 36px;
}

.ml-44 {
  margin-left: 44px;
}

.ml-48 {
  margin-left: 48px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-26 {
  margin-top: 26px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-38 {
  margin-top: 38px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-48 {
  margin-top: 48px;
}

.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
  line-height: 36px;
}

.font-32 {
  font-size: 32px;
}

.font-36 {
  font-size: 30px;
}

.font-40 {
  font-size: 40px;
}

.font-weight-b {
  // font-weight: bold;
  // font-variation-settings: "wght" 700;
  font-family: "Poppins-ExtraBold";
}

.font-weight-6 {
  // font-weight: 600;
  // font-variation-settings: "wght" 600;
  font-family: "Poppins-Bold";
}

.font-weight-5 {
  // font-weight: 500;
  // font-variation-settings: "wght" 500;
  font-family: "Poppins-Medium";
}

.font-weight-3 {
  // font-weight: 300;
  // font-variation-settings: "wght" 300;
  font-family: "Poppins-Light";
}

.font-weight-4 {
  font-family: "Poppins";
}

.color1 {
  color: #ffffff;
}

.color2 {
  color: #9b96ff;
}

.color3 {
  color: rgb(255, 255, 255, 0.25);
}

.color4 {
  color: #eef2f9;
}

.color5 {
  color: #8f92a1;
}

.color6 {
  color: #6b75a5;
}

.color7 {
  color: #f89542;
}

.color8 {
  color: rgb(255, 255, 255, 0.5);
}

.color9 {
  color: rgba(238, 242, 249, 0.5);
}

.color10 {
  color: #a4fc5d;
}

.color11 {
  color: #04143d;
}

.color12 {
  color: #724a01;
}

.color13 {
  color: #593507;
}

.color14 {
  color: rgba(255, 255, 255, 0.54);
}

.color15 {
  color: #faff00;
}

.color16 {
  color: #69D0C6;
}

.color17 {
  color: rgba(255, 255, 255, 0.7);
}

.color18 {
  color: #0AD5BD;
}

.color19 {
  color: #FFD100;
}

.bg1 {
  background: #20253e;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-b {
  border-bottom: 1px solid #2f2d6c;
}

.underline {
  text-decoration-line: underline;
}

a {
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.MuiLink-root.active {
  text-decoration: none !important;
}

img {
  max-width: 100%;
  cursor: pointer;
}

.word-break {
  word-break: break-word;
}

.jYxAGf {
  z-index: 1000;
}

.MuiButtonBase-root.Mui-disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}