.Tab.block {
  display: grid;
  grid-auto-flow: column;
  font-size: 1.4rem;
  border-radius: 5px;
  overflow: hidden;
  color: #9B96FF;
  /* border: 1px solid #9B96FF; */
  background: linear-gradient(0deg, #28229C, #28229C), #5E58DB;;
  box-shadow: inset 0px 0px 30px 5px rgba(255, 255, 255, 0.01);
}

.Tab-option-icon {
  margin-right: 0.465rem;
  opacity: 0.7;
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  /* font-size: 1.5rem; */
}

.Tab.block .Tab-option:hover {
  color: rgba(255, 255, 255, 1);
  background: #4540AA;
}

.Tab.block .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  background: #4540AA;
  color: rgba(255, 255, 255, 1);
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 1.5rem;
  font-size: 1.5rem;
}

.Tab.inline .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
}
