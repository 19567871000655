.header-view {
    border-bottom: 1px solid #2F2D6C;

    .header {
        width: 100%;
        height: 90px;

        .logo {
            margin-left: 36px;
        }

        .setting {
            margin-right: 32px;
        }
    }
}

.menuList {
    margin-left: 60px;

    .menuItem {
        margin-left: 42px;
        color: rgb(255, 255, 255, 0.25);

        &:hover {
            cursor: pointer;
            color: #EEF2F9;
        }
    }

    .menuItemActive {
        color: #EEF2F9;
    }
}

.MuiSvgIcon-root {
    width: auto !important;
    height: auto !important;
}

.switchBtn,
.connectBtn {

    &:hover {
        .downIcon {
            path {
                fill: #FFFFFF;
            }
        }
    }
}

.setting {

    &:hover {
        .downIcon {
            path {
                fill: #FFFFFF;
            }
        }
    }
}

.setting {
    width: 35px;
    height: 35px;
    border: 1px solid #9B96FF;
    border-radius: 8px;

    &:hover {
        cursor: pointer;
        background: #4540AA;
        border: 1px solid #4540AA;
    }
}

.getBtnBox,
.switchBtnBox,
.connectBtnBox {
    height: 36px;

    .MuiButton-root {
        line-height: 36px;
        width: 100%;
        max-width: 100%;
        border-radius: 9px;
        font-size: 13px;
        font-weight: 700;
        font-family: "DM Sans";
    }

}

.getBtnBox {
    .MuiButton-contained {
        background-color: #F89542;
        color: #FFFFFF;
    }

    .MuiButton-contained.Mui-disabled {
        background-color: transparent;
        color: #F89542;
        border: 1px solid #F89542;
    }
}

.switchBtnBox,
.connectBtnBox {
    .MuiButton-contained {
        color: #9B96FF;
        border: 1px solid #9B96FF;
        background-color: transparent;
        &:hover {
            background-color: #4540AA;
            border: 1px solid #4540AA;
            color: #FFFFFF;
        }
    }
}

.getBtnBox {
    .MuiButton-contained:hover {
        background-color: #FFAF6B;
    }
}

.MuiDrawer-paper {
    width: auto !important;
}

.drawerBg {
    width: 80vw;
    height: 100%;
    background: #0D022F;

    .drawerClose {
        margin-top: 30px;
        margin-right: 40px;
    }
}

@media only screen and (max-width: 1490px) {
    .menuList {
        margin-left: 32px;

        .menuItem {
            margin-left: 28px;
        }
    }
}

@media only screen and (max-width: 1280px) {
    .header-view {
        .header {

            .connectBtn {
                margin-right: 24px;
            }
        }
    }

    .menuList {
        margin-top: 16px;
        margin-left: 0;
        margin-right: 40px;

        .menuItem {
            margin-left: 0;
            margin-top: 24px;
        }
    }

    .getBtnBox {
        margin-top: 30px;
    }

    .drawerBg {

        .getBtnBox,
        .switchBtnBox,
        .connectBtnBox {
            margin-right: 40px;
        }

        .switchBtnBox,
        .connectBtnBox {
            margin-top: 24px;
        }

        .linkList {
            margin-bottom: 36px;
            margin-right: 40px;

            .linkItem {
                &:not(:first-child) {
                    margin-left: 30px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1080px) {
    .header-view {
        .header {

            .logo {
                margin-left: 24px;
            }

            .connectBtn {
                margin-right: 16px;
            }
        }
    }
}